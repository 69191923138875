@keyframes fadeInUnblur {
  0% {
    opacity: 0;
    text-shadow: 0 0 #859900;
    filter: blur(5px);
  }

  100% {
    opacity: 1;
    text-shadow: 0 0 5px #859900;
    filter: blur();
  }
}

@keyframes glow {
  0% {
    color: #b7d200;
    text-shadow: 0 0 10px #859900;
  }

  100% {
    color: #859900;
    text-shadow: 0 0 5px #859900;
  }
}

@keyframes lineglow {
  0% {
    opacity: 0;
    width: 0;
    max-height: 0;
  }

  33% {
    opacity: 1;
    width: 100%;
    max-height: 0;
  }

  100% {
    opacity: 1;
    width: 100%;
    max-height: 100vh;
  }
}

@keyframes modalglow {
  0% {
    opacity: 0;
    width: 0;
    max-height: 0;
    padding: 0;
  }

  33% {
    opacity: 1;
    width: 80%;
    max-height: 0;
    padding: 0;
  }

  100% {
    opacity: 1;
    width: 80%;
    max-height: 80%;
    padding: 15px 30px;
  }
}

@keyframes ripple {
  0% {
    opacity: .8;
    transform: scale(0);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.ripple {
  pointer-events: none;
  opacity: .8;
  filter: blur(5px);
  background-color: #859900;
  border-radius: 50%;
  width: 200vw;
  height: 200vw;
  animation: .666s forwards ripple;
  position: fixed;
}

@font-face {
  font-family: Kode Mono;
  font-style: normal;
  font-weight: 400;
  src: url("KodeMono-Regular.95ca7c46.ttf") format("truetype");
  font-display: swap;
}

.fa {
  font-family: var(--fa-style-family, "Font Awesome 6 Free");
  font-weight: var(--fa-style, 900);
}

.fas, .far, .fab, .fa-solid, .fa-regular, .fa-brands, .fa {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-variant: normal;
  text-rendering: auto;
  font-style: normal;
  line-height: 1;
}

.fas:before, .far:before, .fab:before, .fa-solid:before, .fa-regular:before, .fa-brands:before, .fa:before {
  content: var(--fa);
}

.fa-classic, .fas, .fa-solid, .far, .fa-regular {
  font-family: "Font Awesome 6 Free";
}

.fa-brands, .fab {
  font-family: "Font Awesome 6 Brands";
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-2xs {
  vertical-align: .225em;
  font-size: .625em;
  line-height: .1em;
}

.fa-xs {
  vertical-align: .125em;
  font-size: .75em;
  line-height: .0833333em;
}

.fa-sm {
  vertical-align: .0535714em;
  font-size: .875em;
  line-height: .0714286em;
}

.fa-lg {
  vertical-align: -.075em;
  font-size: 1.25em;
  line-height: .05em;
}

.fa-xl {
  vertical-align: -.125em;
  font-size: 1.5em;
  line-height: .0416667em;
}

.fa-2xl {
  vertical-align: -.1875em;
  font-size: 2em;
  line-height: .03125em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  margin-left: var(--fa-li-margin, 2.5em);
  padding-left: 0;
  list-style-type: none;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  left: calc(-1 * var(--fa-li-width, 2em));
  text-align: center;
  width: var(--fa-li-width, 2em);
  line-height: inherit;
  position: absolute;
}

.fa-border {
  border-color: var(--fa-border-color, #eee);
  border-radius: var(--fa-border-radius, .1em);
  border-style: var(--fa-border-style, solid);
  border-width: var(--fa-border-width, .08em);
  padding: var(--fa-border-padding, .2em .25em .15em);
}

.fa-pull-left {
  float: left;
  margin-right: var(--fa-pull-margin, .3em);
}

.fa-pull-right {
  float: right;
  margin-left: var(--fa-pull-margin, .3em);
}

.fa-beat {
  animation-name: fa-beat;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-bounce {
  animation-name: fa-bounce;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(.28, .84, .42, 1));
}

.fa-fade {
  animation-name: fa-fade;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(.4, 0, .6, 1));
}

.fa-beat-fade {
  animation-name: fa-beat-fade;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(.4, 0, .6, 1));
}

.fa-flip {
  animation-name: fa-flip;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-shake {
  animation-name: fa-shake;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin {
  animation-name: fa-spin;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 2s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin-reverse {
  --fa-animation-direction: reverse;
}

.fa-pulse, .fa-spin-pulse {
  animation-name: fa-spin;
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, steps(8));
}

@media (prefers-reduced-motion: reduce) {
  .fa-beat, .fa-bounce, .fa-fade, .fa-beat-fade, .fa-flip, .fa-pulse, .fa-shake, .fa-spin, .fa-spin-pulse {
    transition-duration: 0s;
    transition-delay: 0s;
    animation-duration: 1ms;
    animation-iteration-count: 1;
    animation-delay: -1ms;
  }
}

@keyframes fa-beat {
  0%, 90% {
    transform: scale(1);
  }

  45% {
    transform: scale(var(--fa-beat-scale, 1.25));
  }
}

@keyframes fa-bounce {
  0% {
    transform: scale(1)translateY(0);
  }

  10% {
    transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, .9)) translateY(0);
  }

  30% {
    transform: scale(var(--fa-bounce-jump-scale-x, .9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -.5em));
  }

  50% {
    transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, .95)) translateY(0);
  }

  57% {
    transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -.125em));
  }

  64% {
    transform: scale(1)translateY(0);
  }

  100% {
    transform: scale(1)translateY(0);
  }
}

@keyframes fa-fade {
  50% {
    opacity: var(--fa-fade-opacity, .4);
  }
}

@keyframes fa-beat-fade {
  0%, 100% {
    opacity: var(--fa-beat-fade-opacity, .4);
    transform: scale(1);
  }

  50% {
    opacity: 1;
    transform: scale(var(--fa-beat-fade-scale, 1.125));
  }
}

@keyframes fa-flip {
  50% {
    transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
  }
}

@keyframes fa-shake {
  0% {
    transform: rotate(-15deg);
  }

  4% {
    transform: rotate(15deg);
  }

  8%, 24% {
    transform: rotate(-18deg);
  }

  12%, 28% {
    transform: rotate(18deg);
  }

  16% {
    transform: rotate(-22deg);
  }

  20% {
    transform: rotate(22deg);
  }

  32% {
    transform: rotate(-12deg);
  }

  36% {
    transform: rotate(12deg);
  }

  40%, 100% {
    transform: rotate(0);
  }
}

@keyframes fa-spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.fa-rotate-90 {
  transform: rotate(90deg);
}

.fa-rotate-180 {
  transform: rotate(180deg);
}

.fa-rotate-270 {
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  transform: scale(1, -1);
}

.fa-flip-both, .fa-flip-horizontal.fa-flip-vertical {
  transform: scale(-1);
}

.fa-rotate-by {
  transform: rotate(var(--fa-rotate-angle, 0));
}

.fa-stack {
  vertical-align: middle;
  width: 2.5em;
  height: 2em;
  line-height: 2em;
  display: inline-block;
  position: relative;
}

.fa-stack-1x, .fa-stack-2x {
  text-align: center;
  width: 100%;
  z-index: var(--fa-stack-z-index, auto);
  position: absolute;
  left: 0;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: var(--fa-inverse, #fff);
}

.fa-0 {
  --fa: "0";
}

.fa-1 {
  --fa: "1";
}

.fa-2 {
  --fa: "2";
}

.fa-3 {
  --fa: "3";
}

.fa-4 {
  --fa: "4";
}

.fa-5 {
  --fa: "5";
}

.fa-6 {
  --fa: "6";
}

.fa-7 {
  --fa: "7";
}

.fa-8 {
  --fa: "8";
}

.fa-9 {
  --fa: "9";
}

.fa-fill-drip {
  --fa: "";
}

.fa-arrows-to-circle {
  --fa: "";
}

.fa-circle-chevron-right, .fa-chevron-circle-right {
  --fa: "";
}

.fa-at {
  --fa: "@";
}

.fa-trash-can, .fa-trash-alt {
  --fa: "";
}

.fa-text-height {
  --fa: "";
}

.fa-user-xmark, .fa-user-times {
  --fa: "";
}

.fa-stethoscope {
  --fa: "";
}

.fa-message, .fa-comment-alt {
  --fa: "";
}

.fa-info {
  --fa: "";
}

.fa-down-left-and-up-right-to-center, .fa-compress-alt {
  --fa: "";
}

.fa-explosion {
  --fa: "";
}

.fa-file-lines, .fa-file-alt, .fa-file-text {
  --fa: "";
}

.fa-wave-square {
  --fa: "";
}

.fa-ring {
  --fa: "";
}

.fa-building-un {
  --fa: "";
}

.fa-dice-three {
  --fa: "";
}

.fa-calendar-days, .fa-calendar-alt {
  --fa: "";
}

.fa-anchor-circle-check {
  --fa: "";
}

.fa-building-circle-arrow-right {
  --fa: "";
}

.fa-volleyball, .fa-volleyball-ball {
  --fa: "";
}

.fa-arrows-up-to-line {
  --fa: "";
}

.fa-sort-down, .fa-sort-desc {
  --fa: "";
}

.fa-circle-minus, .fa-minus-circle {
  --fa: "";
}

.fa-door-open {
  --fa: "";
}

.fa-right-from-bracket, .fa-sign-out-alt {
  --fa: "";
}

.fa-atom {
  --fa: "";
}

.fa-soap {
  --fa: "";
}

.fa-icons, .fa-heart-music-camera-bolt {
  --fa: "";
}

.fa-microphone-lines-slash, .fa-microphone-alt-slash {
  --fa: "";
}

.fa-bridge-circle-check {
  --fa: "";
}

.fa-pump-medical {
  --fa: "";
}

.fa-fingerprint {
  --fa: "";
}

.fa-hand-point-right {
  --fa: "";
}

.fa-magnifying-glass-location, .fa-search-location {
  --fa: "";
}

.fa-forward-step, .fa-step-forward {
  --fa: "";
}

.fa-face-smile-beam, .fa-smile-beam {
  --fa: "";
}

.fa-flag-checkered {
  --fa: "";
}

.fa-football, .fa-football-ball {
  --fa: "";
}

.fa-school-circle-exclamation {
  --fa: "";
}

.fa-crop {
  --fa: "";
}

.fa-angles-down, .fa-angle-double-down {
  --fa: "";
}

.fa-users-rectangle {
  --fa: "";
}

.fa-people-roof {
  --fa: "";
}

.fa-people-line {
  --fa: "";
}

.fa-beer-mug-empty, .fa-beer {
  --fa: "";
}

.fa-diagram-predecessor {
  --fa: "";
}

.fa-arrow-up-long, .fa-long-arrow-up {
  --fa: "";
}

.fa-fire-flame-simple, .fa-burn {
  --fa: "";
}

.fa-person, .fa-male {
  --fa: "";
}

.fa-laptop {
  --fa: "";
}

.fa-file-csv {
  --fa: "";
}

.fa-menorah {
  --fa: "";
}

.fa-truck-plane {
  --fa: "";
}

.fa-record-vinyl {
  --fa: "";
}

.fa-face-grin-stars, .fa-grin-stars {
  --fa: "";
}

.fa-bong {
  --fa: "";
}

.fa-spaghetti-monster-flying, .fa-pastafarianism {
  --fa: "";
}

.fa-arrow-down-up-across-line {
  --fa: "";
}

.fa-spoon, .fa-utensil-spoon {
  --fa: "";
}

.fa-jar-wheat {
  --fa: "";
}

.fa-envelopes-bulk, .fa-mail-bulk {
  --fa: "";
}

.fa-file-circle-exclamation {
  --fa: "";
}

.fa-circle-h, .fa-hospital-symbol {
  --fa: "";
}

.fa-pager {
  --fa: "";
}

.fa-address-book, .fa-contact-book {
  --fa: "";
}

.fa-strikethrough {
  --fa: "";
}

.fa-k {
  --fa: "K";
}

.fa-landmark-flag {
  --fa: "";
}

.fa-pencil, .fa-pencil-alt {
  --fa: "";
}

.fa-backward {
  --fa: "";
}

.fa-caret-right {
  --fa: "";
}

.fa-comments {
  --fa: "";
}

.fa-paste, .fa-file-clipboard {
  --fa: "";
}

.fa-code-pull-request {
  --fa: "";
}

.fa-clipboard-list {
  --fa: "";
}

.fa-truck-ramp-box, .fa-truck-loading {
  --fa: "";
}

.fa-user-check {
  --fa: "";
}

.fa-vial-virus {
  --fa: "";
}

.fa-sheet-plastic {
  --fa: "";
}

.fa-blog {
  --fa: "";
}

.fa-user-ninja {
  --fa: "";
}

.fa-person-arrow-up-from-line {
  --fa: "";
}

.fa-scroll-torah, .fa-torah {
  --fa: "";
}

.fa-broom-ball, .fa-quidditch, .fa-quidditch-broom-ball {
  --fa: "";
}

.fa-toggle-off {
  --fa: "";
}

.fa-box-archive, .fa-archive {
  --fa: "";
}

.fa-person-drowning {
  --fa: "";
}

.fa-arrow-down-9-1, .fa-sort-numeric-desc, .fa-sort-numeric-down-alt {
  --fa: "";
}

.fa-face-grin-tongue-squint, .fa-grin-tongue-squint {
  --fa: "";
}

.fa-spray-can {
  --fa: "";
}

.fa-truck-monster {
  --fa: "";
}

.fa-w {
  --fa: "W";
}

.fa-earth-africa, .fa-globe-africa {
  --fa: "";
}

.fa-rainbow {
  --fa: "";
}

.fa-circle-notch {
  --fa: "";
}

.fa-tablet-screen-button, .fa-tablet-alt {
  --fa: "";
}

.fa-paw {
  --fa: "";
}

.fa-cloud {
  --fa: "";
}

.fa-trowel-bricks {
  --fa: "";
}

.fa-face-flushed, .fa-flushed {
  --fa: "";
}

.fa-hospital-user {
  --fa: "";
}

.fa-tent-arrow-left-right {
  --fa: "";
}

.fa-gavel, .fa-legal {
  --fa: "";
}

.fa-binoculars {
  --fa: "";
}

.fa-microphone-slash {
  --fa: "";
}

.fa-box-tissue {
  --fa: "";
}

.fa-motorcycle {
  --fa: "";
}

.fa-bell-concierge, .fa-concierge-bell {
  --fa: "";
}

.fa-pen-ruler, .fa-pencil-ruler {
  --fa: "";
}

.fa-people-arrows, .fa-people-arrows-left-right {
  --fa: "";
}

.fa-mars-and-venus-burst {
  --fa: "";
}

.fa-square-caret-right, .fa-caret-square-right {
  --fa: "";
}

.fa-scissors, .fa-cut {
  --fa: "";
}

.fa-sun-plant-wilt {
  --fa: "";
}

.fa-toilets-portable {
  --fa: "";
}

.fa-hockey-puck {
  --fa: "";
}

.fa-table {
  --fa: "";
}

.fa-magnifying-glass-arrow-right {
  --fa: "";
}

.fa-tachograph-digital, .fa-digital-tachograph {
  --fa: "";
}

.fa-users-slash {
  --fa: "";
}

.fa-clover {
  --fa: "";
}

.fa-reply, .fa-mail-reply {
  --fa: "";
}

.fa-star-and-crescent {
  --fa: "";
}

.fa-house-fire {
  --fa: "";
}

.fa-square-minus, .fa-minus-square {
  --fa: "";
}

.fa-helicopter {
  --fa: "";
}

.fa-compass {
  --fa: "";
}

.fa-square-caret-down, .fa-caret-square-down {
  --fa: "";
}

.fa-file-circle-question {
  --fa: "";
}

.fa-laptop-code {
  --fa: "";
}

.fa-swatchbook {
  --fa: "";
}

.fa-prescription-bottle {
  --fa: "";
}

.fa-bars, .fa-navicon {
  --fa: "";
}

.fa-people-group {
  --fa: "";
}

.fa-hourglass-end, .fa-hourglass-3 {
  --fa: "";
}

.fa-heart-crack, .fa-heart-broken {
  --fa: "";
}

.fa-square-up-right, .fa-external-link-square-alt {
  --fa: "";
}

.fa-face-kiss-beam, .fa-kiss-beam {
  --fa: "";
}

.fa-film {
  --fa: "";
}

.fa-ruler-horizontal {
  --fa: "";
}

.fa-people-robbery {
  --fa: "";
}

.fa-lightbulb {
  --fa: "";
}

.fa-caret-left {
  --fa: "";
}

.fa-circle-exclamation, .fa-exclamation-circle {
  --fa: "";
}

.fa-school-circle-xmark {
  --fa: "";
}

.fa-arrow-right-from-bracket, .fa-sign-out {
  --fa: "";
}

.fa-circle-chevron-down, .fa-chevron-circle-down {
  --fa: "";
}

.fa-unlock-keyhole, .fa-unlock-alt {
  --fa: "";
}

.fa-cloud-showers-heavy {
  --fa: "";
}

.fa-headphones-simple, .fa-headphones-alt {
  --fa: "";
}

.fa-sitemap {
  --fa: "";
}

.fa-circle-dollar-to-slot, .fa-donate {
  --fa: "";
}

.fa-memory {
  --fa: "";
}

.fa-road-spikes {
  --fa: "";
}

.fa-fire-burner {
  --fa: "";
}

.fa-flag {
  --fa: "";
}

.fa-hanukiah {
  --fa: "";
}

.fa-feather {
  --fa: "";
}

.fa-volume-low, .fa-volume-down {
  --fa: "";
}

.fa-comment-slash {
  --fa: "";
}

.fa-cloud-sun-rain {
  --fa: "";
}

.fa-compress {
  --fa: "";
}

.fa-wheat-awn, .fa-wheat-alt {
  --fa: "";
}

.fa-ankh {
  --fa: "";
}

.fa-hands-holding-child {
  --fa: "";
}

.fa-asterisk {
  --fa: "*";
}

.fa-square-check, .fa-check-square {
  --fa: "";
}

.fa-peseta-sign {
  --fa: "";
}

.fa-heading, .fa-header {
  --fa: "";
}

.fa-ghost {
  --fa: "";
}

.fa-list, .fa-list-squares {
  --fa: "";
}

.fa-square-phone-flip, .fa-phone-square-alt {
  --fa: "";
}

.fa-cart-plus {
  --fa: "";
}

.fa-gamepad {
  --fa: "";
}

.fa-circle-dot, .fa-dot-circle {
  --fa: "";
}

.fa-face-dizzy, .fa-dizzy {
  --fa: "";
}

.fa-egg {
  --fa: "";
}

.fa-house-medical-circle-xmark {
  --fa: "";
}

.fa-campground {
  --fa: "";
}

.fa-folder-plus {
  --fa: "";
}

.fa-futbol, .fa-futbol-ball, .fa-soccer-ball {
  --fa: "";
}

.fa-paintbrush, .fa-paint-brush {
  --fa: "";
}

.fa-lock {
  --fa: "";
}

.fa-gas-pump {
  --fa: "";
}

.fa-hot-tub-person, .fa-hot-tub {
  --fa: "";
}

.fa-map-location, .fa-map-marked {
  --fa: "";
}

.fa-house-flood-water {
  --fa: "";
}

.fa-tree {
  --fa: "";
}

.fa-bridge-lock {
  --fa: "";
}

.fa-sack-dollar {
  --fa: "";
}

.fa-pen-to-square, .fa-edit {
  --fa: "";
}

.fa-car-side {
  --fa: "";
}

.fa-share-nodes, .fa-share-alt {
  --fa: "";
}

.fa-heart-circle-minus {
  --fa: "";
}

.fa-hourglass-half, .fa-hourglass-2 {
  --fa: "";
}

.fa-microscope {
  --fa: "";
}

.fa-sink {
  --fa: "";
}

.fa-bag-shopping, .fa-shopping-bag {
  --fa: "";
}

.fa-arrow-down-z-a, .fa-sort-alpha-desc, .fa-sort-alpha-down-alt {
  --fa: "";
}

.fa-mitten {
  --fa: "";
}

.fa-person-rays {
  --fa: "";
}

.fa-users {
  --fa: "";
}

.fa-eye-slash {
  --fa: "";
}

.fa-flask-vial {
  --fa: "";
}

.fa-hand, .fa-hand-paper {
  --fa: "";
}

.fa-om {
  --fa: "";
}

.fa-worm {
  --fa: "";
}

.fa-house-circle-xmark {
  --fa: "";
}

.fa-plug {
  --fa: "";
}

.fa-chevron-up {
  --fa: "";
}

.fa-hand-spock {
  --fa: "";
}

.fa-stopwatch {
  --fa: "";
}

.fa-face-kiss, .fa-kiss {
  --fa: "";
}

.fa-bridge-circle-xmark {
  --fa: "";
}

.fa-face-grin-tongue, .fa-grin-tongue {
  --fa: "";
}

.fa-chess-bishop {
  --fa: "";
}

.fa-face-grin-wink, .fa-grin-wink {
  --fa: "";
}

.fa-ear-deaf, .fa-deaf, .fa-deafness, .fa-hard-of-hearing {
  --fa: "";
}

.fa-road-circle-check {
  --fa: "";
}

.fa-dice-five {
  --fa: "";
}

.fa-square-rss, .fa-rss-square {
  --fa: "";
}

.fa-land-mine-on {
  --fa: "";
}

.fa-i-cursor {
  --fa: "";
}

.fa-stamp {
  --fa: "";
}

.fa-stairs {
  --fa: "";
}

.fa-i {
  --fa: "I";
}

.fa-hryvnia-sign, .fa-hryvnia {
  --fa: "";
}

.fa-pills {
  --fa: "";
}

.fa-face-grin-wide, .fa-grin-alt {
  --fa: "";
}

.fa-tooth {
  --fa: "";
}

.fa-v {
  --fa: "V";
}

.fa-bangladeshi-taka-sign {
  --fa: "";
}

.fa-bicycle {
  --fa: "";
}

.fa-staff-snake, .fa-rod-asclepius, .fa-rod-snake, .fa-staff-aesculapius {
  --fa: "";
}

.fa-head-side-cough-slash {
  --fa: "";
}

.fa-truck-medical, .fa-ambulance {
  --fa: "";
}

.fa-wheat-awn-circle-exclamation {
  --fa: "";
}

.fa-snowman {
  --fa: "";
}

.fa-mortar-pestle {
  --fa: "";
}

.fa-road-barrier {
  --fa: "";
}

.fa-school {
  --fa: "";
}

.fa-igloo {
  --fa: "";
}

.fa-joint {
  --fa: "";
}

.fa-angle-right {
  --fa: "";
}

.fa-horse {
  --fa: "";
}

.fa-q {
  --fa: "Q";
}

.fa-g {
  --fa: "G";
}

.fa-notes-medical {
  --fa: "";
}

.fa-temperature-half, .fa-temperature-2, .fa-thermometer-2, .fa-thermometer-half {
  --fa: "";
}

.fa-dong-sign {
  --fa: "";
}

.fa-capsules {
  --fa: "";
}

.fa-poo-storm, .fa-poo-bolt {
  --fa: "";
}

.fa-face-frown-open, .fa-frown-open {
  --fa: "";
}

.fa-hand-point-up {
  --fa: "";
}

.fa-money-bill {
  --fa: "";
}

.fa-bookmark {
  --fa: "";
}

.fa-align-justify {
  --fa: "";
}

.fa-umbrella-beach {
  --fa: "";
}

.fa-helmet-un {
  --fa: "";
}

.fa-bullseye {
  --fa: "";
}

.fa-bacon {
  --fa: "";
}

.fa-hand-point-down {
  --fa: "";
}

.fa-arrow-up-from-bracket {
  --fa: "";
}

.fa-folder, .fa-folder-blank {
  --fa: "";
}

.fa-file-waveform, .fa-file-medical-alt {
  --fa: "";
}

.fa-radiation {
  --fa: "";
}

.fa-chart-simple {
  --fa: "";
}

.fa-mars-stroke {
  --fa: "";
}

.fa-vial {
  --fa: "";
}

.fa-gauge, .fa-dashboard, .fa-gauge-med, .fa-tachometer-alt-average {
  --fa: "";
}

.fa-wand-magic-sparkles, .fa-magic-wand-sparkles {
  --fa: "";
}

.fa-e {
  --fa: "E";
}

.fa-pen-clip, .fa-pen-alt {
  --fa: "";
}

.fa-bridge-circle-exclamation {
  --fa: "";
}

.fa-user {
  --fa: "";
}

.fa-school-circle-check {
  --fa: "";
}

.fa-dumpster {
  --fa: "";
}

.fa-van-shuttle, .fa-shuttle-van {
  --fa: "";
}

.fa-building-user {
  --fa: "";
}

.fa-square-caret-left, .fa-caret-square-left {
  --fa: "";
}

.fa-highlighter {
  --fa: "";
}

.fa-key {
  --fa: "";
}

.fa-bullhorn {
  --fa: "";
}

.fa-globe {
  --fa: "";
}

.fa-synagogue {
  --fa: "";
}

.fa-person-half-dress {
  --fa: "";
}

.fa-road-bridge {
  --fa: "";
}

.fa-location-arrow {
  --fa: "";
}

.fa-c {
  --fa: "C";
}

.fa-tablet-button {
  --fa: "";
}

.fa-building-lock {
  --fa: "";
}

.fa-pizza-slice {
  --fa: "";
}

.fa-money-bill-wave {
  --fa: "";
}

.fa-chart-area, .fa-area-chart {
  --fa: "";
}

.fa-house-flag {
  --fa: "";
}

.fa-person-circle-minus {
  --fa: "";
}

.fa-ban, .fa-cancel {
  --fa: "";
}

.fa-camera-rotate {
  --fa: "";
}

.fa-spray-can-sparkles, .fa-air-freshener {
  --fa: "";
}

.fa-star {
  --fa: "";
}

.fa-repeat {
  --fa: "";
}

.fa-cross {
  --fa: "";
}

.fa-box {
  --fa: "";
}

.fa-venus-mars {
  --fa: "";
}

.fa-arrow-pointer, .fa-mouse-pointer {
  --fa: "";
}

.fa-maximize, .fa-expand-arrows-alt {
  --fa: "";
}

.fa-charging-station {
  --fa: "";
}

.fa-shapes, .fa-triangle-circle-square {
  --fa: "";
}

.fa-shuffle, .fa-random {
  --fa: "";
}

.fa-person-running, .fa-running {
  --fa: "";
}

.fa-mobile-retro {
  --fa: "";
}

.fa-grip-lines-vertical {
  --fa: "";
}

.fa-spider {
  --fa: "";
}

.fa-hands-bound {
  --fa: "";
}

.fa-file-invoice-dollar {
  --fa: "";
}

.fa-plane-circle-exclamation {
  --fa: "";
}

.fa-x-ray {
  --fa: "";
}

.fa-spell-check {
  --fa: "";
}

.fa-slash {
  --fa: "";
}

.fa-computer-mouse, .fa-mouse {
  --fa: "";
}

.fa-arrow-right-to-bracket, .fa-sign-in {
  --fa: "";
}

.fa-shop-slash, .fa-store-alt-slash {
  --fa: "";
}

.fa-server {
  --fa: "";
}

.fa-virus-covid-slash {
  --fa: "";
}

.fa-shop-lock {
  --fa: "";
}

.fa-hourglass-start, .fa-hourglass-1 {
  --fa: "";
}

.fa-blender-phone {
  --fa: "";
}

.fa-building-wheat {
  --fa: "";
}

.fa-person-breastfeeding {
  --fa: "";
}

.fa-right-to-bracket, .fa-sign-in-alt {
  --fa: "";
}

.fa-venus {
  --fa: "";
}

.fa-passport {
  --fa: "";
}

.fa-thumbtack-slash, .fa-thumb-tack-slash {
  --fa: "";
}

.fa-heart-pulse, .fa-heartbeat {
  --fa: "";
}

.fa-people-carry-box, .fa-people-carry {
  --fa: "";
}

.fa-temperature-high {
  --fa: "";
}

.fa-microchip {
  --fa: "";
}

.fa-crown {
  --fa: "";
}

.fa-weight-hanging {
  --fa: "";
}

.fa-xmarks-lines {
  --fa: "";
}

.fa-file-prescription {
  --fa: "";
}

.fa-weight-scale, .fa-weight {
  --fa: "";
}

.fa-user-group, .fa-user-friends {
  --fa: "";
}

.fa-arrow-up-a-z, .fa-sort-alpha-up {
  --fa: "";
}

.fa-chess-knight {
  --fa: "";
}

.fa-face-laugh-squint, .fa-laugh-squint {
  --fa: "";
}

.fa-wheelchair {
  --fa: "";
}

.fa-circle-arrow-up, .fa-arrow-circle-up {
  --fa: "";
}

.fa-toggle-on {
  --fa: "";
}

.fa-person-walking, .fa-walking {
  --fa: "";
}

.fa-l {
  --fa: "L";
}

.fa-fire {
  --fa: "";
}

.fa-bed-pulse, .fa-procedures {
  --fa: "";
}

.fa-shuttle-space, .fa-space-shuttle {
  --fa: "";
}

.fa-face-laugh, .fa-laugh {
  --fa: "";
}

.fa-folder-open {
  --fa: "";
}

.fa-heart-circle-plus {
  --fa: "";
}

.fa-code-fork {
  --fa: "";
}

.fa-city {
  --fa: "";
}

.fa-microphone-lines, .fa-microphone-alt {
  --fa: "";
}

.fa-pepper-hot {
  --fa: "";
}

.fa-unlock {
  --fa: "";
}

.fa-colon-sign {
  --fa: "";
}

.fa-headset {
  --fa: "";
}

.fa-store-slash {
  --fa: "";
}

.fa-road-circle-xmark {
  --fa: "";
}

.fa-user-minus {
  --fa: "";
}

.fa-mars-stroke-up, .fa-mars-stroke-v {
  --fa: "";
}

.fa-champagne-glasses, .fa-glass-cheers {
  --fa: "";
}

.fa-clipboard {
  --fa: "";
}

.fa-house-circle-exclamation {
  --fa: "";
}

.fa-file-arrow-up, .fa-file-upload {
  --fa: "";
}

.fa-wifi, .fa-wifi-3, .fa-wifi-strong {
  --fa: "";
}

.fa-bath, .fa-bathtub {
  --fa: "";
}

.fa-underline {
  --fa: "";
}

.fa-user-pen, .fa-user-edit {
  --fa: "";
}

.fa-signature {
  --fa: "";
}

.fa-stroopwafel {
  --fa: "";
}

.fa-bold {
  --fa: "";
}

.fa-anchor-lock {
  --fa: "";
}

.fa-building-ngo {
  --fa: "";
}

.fa-manat-sign {
  --fa: "";
}

.fa-not-equal {
  --fa: "";
}

.fa-border-top-left, .fa-border-style {
  --fa: "";
}

.fa-map-location-dot, .fa-map-marked-alt {
  --fa: "";
}

.fa-jedi {
  --fa: "";
}

.fa-square-poll-vertical, .fa-poll {
  --fa: "";
}

.fa-mug-hot {
  --fa: "";
}

.fa-car-battery, .fa-battery-car {
  --fa: "";
}

.fa-gift {
  --fa: "";
}

.fa-dice-two {
  --fa: "";
}

.fa-chess-queen {
  --fa: "";
}

.fa-glasses {
  --fa: "";
}

.fa-chess-board {
  --fa: "";
}

.fa-building-circle-check {
  --fa: "";
}

.fa-person-chalkboard {
  --fa: "";
}

.fa-mars-stroke-right, .fa-mars-stroke-h {
  --fa: "";
}

.fa-hand-back-fist, .fa-hand-rock {
  --fa: "";
}

.fa-square-caret-up, .fa-caret-square-up {
  --fa: "";
}

.fa-cloud-showers-water {
  --fa: "";
}

.fa-chart-bar, .fa-bar-chart {
  --fa: "";
}

.fa-hands-bubbles, .fa-hands-wash {
  --fa: "";
}

.fa-less-than-equal {
  --fa: "";
}

.fa-train {
  --fa: "";
}

.fa-eye-low-vision, .fa-low-vision {
  --fa: "";
}

.fa-crow {
  --fa: "";
}

.fa-sailboat {
  --fa: "";
}

.fa-window-restore {
  --fa: "";
}

.fa-square-plus, .fa-plus-square {
  --fa: "";
}

.fa-torii-gate {
  --fa: "";
}

.fa-frog {
  --fa: "";
}

.fa-bucket {
  --fa: "";
}

.fa-image {
  --fa: "";
}

.fa-microphone {
  --fa: "";
}

.fa-cow {
  --fa: "";
}

.fa-caret-up {
  --fa: "";
}

.fa-screwdriver {
  --fa: "";
}

.fa-folder-closed {
  --fa: "";
}

.fa-house-tsunami {
  --fa: "";
}

.fa-square-nfi {
  --fa: "";
}

.fa-arrow-up-from-ground-water {
  --fa: "";
}

.fa-martini-glass, .fa-glass-martini-alt {
  --fa: "";
}

.fa-square-binary {
  --fa: "";
}

.fa-rotate-left, .fa-rotate-back, .fa-rotate-backward, .fa-undo-alt {
  --fa: "";
}

.fa-table-columns, .fa-columns {
  --fa: "";
}

.fa-lemon {
  --fa: "";
}

.fa-head-side-mask {
  --fa: "";
}

.fa-handshake {
  --fa: "";
}

.fa-gem {
  --fa: "";
}

.fa-dolly, .fa-dolly-box {
  --fa: "";
}

.fa-smoking {
  --fa: "";
}

.fa-minimize, .fa-compress-arrows-alt {
  --fa: "";
}

.fa-monument {
  --fa: "";
}

.fa-snowplow {
  --fa: "";
}

.fa-angles-right, .fa-angle-double-right {
  --fa: "";
}

.fa-cannabis {
  --fa: "";
}

.fa-circle-play, .fa-play-circle {
  --fa: "";
}

.fa-tablets {
  --fa: "";
}

.fa-ethernet {
  --fa: "";
}

.fa-euro-sign, .fa-eur, .fa-euro {
  --fa: "";
}

.fa-chair {
  --fa: "";
}

.fa-circle-check, .fa-check-circle {
  --fa: "";
}

.fa-circle-stop, .fa-stop-circle {
  --fa: "";
}

.fa-compass-drafting, .fa-drafting-compass {
  --fa: "";
}

.fa-plate-wheat {
  --fa: "";
}

.fa-icicles {
  --fa: "";
}

.fa-person-shelter {
  --fa: "";
}

.fa-neuter {
  --fa: "";
}

.fa-id-badge {
  --fa: "";
}

.fa-marker {
  --fa: "";
}

.fa-face-laugh-beam, .fa-laugh-beam {
  --fa: "";
}

.fa-helicopter-symbol {
  --fa: "";
}

.fa-universal-access {
  --fa: "";
}

.fa-circle-chevron-up, .fa-chevron-circle-up {
  --fa: "";
}

.fa-lari-sign {
  --fa: "";
}

.fa-volcano {
  --fa: "";
}

.fa-person-walking-dashed-line-arrow-right {
  --fa: "";
}

.fa-sterling-sign, .fa-gbp, .fa-pound-sign {
  --fa: "";
}

.fa-viruses {
  --fa: "";
}

.fa-square-person-confined {
  --fa: "";
}

.fa-user-tie {
  --fa: "";
}

.fa-arrow-down-long, .fa-long-arrow-down {
  --fa: "";
}

.fa-tent-arrow-down-to-line {
  --fa: "";
}

.fa-certificate {
  --fa: "";
}

.fa-reply-all, .fa-mail-reply-all {
  --fa: "";
}

.fa-suitcase {
  --fa: "";
}

.fa-person-skating, .fa-skating {
  --fa: "";
}

.fa-filter-circle-dollar, .fa-funnel-dollar {
  --fa: "";
}

.fa-camera-retro {
  --fa: "";
}

.fa-circle-arrow-down, .fa-arrow-circle-down {
  --fa: "";
}

.fa-file-import, .fa-arrow-right-to-file {
  --fa: "";
}

.fa-square-arrow-up-right, .fa-external-link-square {
  --fa: "";
}

.fa-box-open {
  --fa: "";
}

.fa-scroll {
  --fa: "";
}

.fa-spa {
  --fa: "";
}

.fa-location-pin-lock {
  --fa: "";
}

.fa-pause {
  --fa: "";
}

.fa-hill-avalanche {
  --fa: "";
}

.fa-temperature-empty, .fa-temperature-0, .fa-thermometer-0, .fa-thermometer-empty {
  --fa: "";
}

.fa-bomb {
  --fa: "";
}

.fa-registered {
  --fa: "";
}

.fa-address-card, .fa-contact-card, .fa-vcard {
  --fa: "";
}

.fa-scale-unbalanced-flip, .fa-balance-scale-right {
  --fa: "";
}

.fa-subscript {
  --fa: "";
}

.fa-diamond-turn-right, .fa-directions {
  --fa: "";
}

.fa-burst {
  --fa: "";
}

.fa-house-laptop, .fa-laptop-house {
  --fa: "";
}

.fa-face-tired, .fa-tired {
  --fa: "";
}

.fa-money-bills {
  --fa: "";
}

.fa-smog {
  --fa: "";
}

.fa-crutch {
  --fa: "";
}

.fa-cloud-arrow-up, .fa-cloud-upload, .fa-cloud-upload-alt {
  --fa: "";
}

.fa-palette {
  --fa: "";
}

.fa-arrows-turn-right {
  --fa: "";
}

.fa-vest {
  --fa: "";
}

.fa-ferry {
  --fa: "";
}

.fa-arrows-down-to-people {
  --fa: "";
}

.fa-seedling, .fa-sprout {
  --fa: "";
}

.fa-left-right, .fa-arrows-alt-h {
  --fa: "";
}

.fa-boxes-packing {
  --fa: "";
}

.fa-circle-arrow-left, .fa-arrow-circle-left {
  --fa: "";
}

.fa-group-arrows-rotate {
  --fa: "";
}

.fa-bowl-food {
  --fa: "";
}

.fa-candy-cane {
  --fa: "";
}

.fa-arrow-down-wide-short, .fa-sort-amount-asc, .fa-sort-amount-down {
  --fa: "";
}

.fa-cloud-bolt, .fa-thunderstorm {
  --fa: "";
}

.fa-text-slash, .fa-remove-format {
  --fa: "";
}

.fa-face-smile-wink, .fa-smile-wink {
  --fa: "";
}

.fa-file-word {
  --fa: "";
}

.fa-file-powerpoint {
  --fa: "";
}

.fa-arrows-left-right, .fa-arrows-h {
  --fa: "";
}

.fa-house-lock {
  --fa: "";
}

.fa-cloud-arrow-down, .fa-cloud-download, .fa-cloud-download-alt {
  --fa: "";
}

.fa-children {
  --fa: "";
}

.fa-chalkboard, .fa-blackboard {
  --fa: "";
}

.fa-user-large-slash, .fa-user-alt-slash {
  --fa: "";
}

.fa-envelope-open {
  --fa: "";
}

.fa-handshake-simple-slash, .fa-handshake-alt-slash {
  --fa: "";
}

.fa-mattress-pillow {
  --fa: "";
}

.fa-guarani-sign {
  --fa: "";
}

.fa-arrows-rotate, .fa-refresh, .fa-sync {
  --fa: "";
}

.fa-fire-extinguisher {
  --fa: "";
}

.fa-cruzeiro-sign {
  --fa: "";
}

.fa-greater-than-equal {
  --fa: "";
}

.fa-shield-halved, .fa-shield-alt {
  --fa: "";
}

.fa-book-atlas, .fa-atlas {
  --fa: "";
}

.fa-virus {
  --fa: "";
}

.fa-envelope-circle-check {
  --fa: "";
}

.fa-layer-group {
  --fa: "";
}

.fa-arrows-to-dot {
  --fa: "";
}

.fa-archway {
  --fa: "";
}

.fa-heart-circle-check {
  --fa: "";
}

.fa-house-chimney-crack, .fa-house-damage {
  --fa: "";
}

.fa-file-zipper, .fa-file-archive {
  --fa: "";
}

.fa-square {
  --fa: "";
}

.fa-martini-glass-empty, .fa-glass-martini {
  --fa: "";
}

.fa-couch {
  --fa: "";
}

.fa-cedi-sign {
  --fa: "";
}

.fa-italic {
  --fa: "";
}

.fa-table-cells-column-lock {
  --fa: "";
}

.fa-church {
  --fa: "";
}

.fa-comments-dollar {
  --fa: "";
}

.fa-democrat {
  --fa: "";
}

.fa-z {
  --fa: "Z";
}

.fa-person-skiing, .fa-skiing {
  --fa: "";
}

.fa-road-lock {
  --fa: "";
}

.fa-a {
  --fa: "A";
}

.fa-temperature-arrow-down, .fa-temperature-down {
  --fa: "";
}

.fa-feather-pointed, .fa-feather-alt {
  --fa: "";
}

.fa-p {
  --fa: "P";
}

.fa-snowflake {
  --fa: "";
}

.fa-newspaper {
  --fa: "";
}

.fa-rectangle-ad, .fa-ad {
  --fa: "";
}

.fa-circle-arrow-right, .fa-arrow-circle-right {
  --fa: "";
}

.fa-filter-circle-xmark {
  --fa: "";
}

.fa-locust {
  --fa: "";
}

.fa-sort, .fa-unsorted {
  --fa: "";
}

.fa-list-ol, .fa-list-1-2, .fa-list-numeric {
  --fa: "";
}

.fa-person-dress-burst {
  --fa: "";
}

.fa-money-check-dollar, .fa-money-check-alt {
  --fa: "";
}

.fa-vector-square {
  --fa: "";
}

.fa-bread-slice {
  --fa: "";
}

.fa-language {
  --fa: "";
}

.fa-face-kiss-wink-heart, .fa-kiss-wink-heart {
  --fa: "";
}

.fa-filter {
  --fa: "";
}

.fa-question {
  --fa: "?";
}

.fa-file-signature {
  --fa: "";
}

.fa-up-down-left-right, .fa-arrows-alt {
  --fa: "";
}

.fa-house-chimney-user {
  --fa: "";
}

.fa-hand-holding-heart {
  --fa: "";
}

.fa-puzzle-piece {
  --fa: "";
}

.fa-money-check {
  --fa: "";
}

.fa-star-half-stroke, .fa-star-half-alt {
  --fa: "";
}

.fa-code {
  --fa: "";
}

.fa-whiskey-glass, .fa-glass-whiskey {
  --fa: "";
}

.fa-building-circle-exclamation {
  --fa: "";
}

.fa-magnifying-glass-chart {
  --fa: "";
}

.fa-arrow-up-right-from-square, .fa-external-link {
  --fa: "";
}

.fa-cubes-stacked {
  --fa: "";
}

.fa-won-sign, .fa-krw, .fa-won {
  --fa: "";
}

.fa-virus-covid {
  --fa: "";
}

.fa-austral-sign {
  --fa: "";
}

.fa-f {
  --fa: "F";
}

.fa-leaf {
  --fa: "";
}

.fa-road {
  --fa: "";
}

.fa-taxi, .fa-cab {
  --fa: "";
}

.fa-person-circle-plus {
  --fa: "";
}

.fa-chart-pie, .fa-pie-chart {
  --fa: "";
}

.fa-bolt-lightning {
  --fa: "";
}

.fa-sack-xmark {
  --fa: "";
}

.fa-file-excel {
  --fa: "";
}

.fa-file-contract {
  --fa: "";
}

.fa-fish-fins {
  --fa: "";
}

.fa-building-flag {
  --fa: "";
}

.fa-face-grin-beam, .fa-grin-beam {
  --fa: "";
}

.fa-object-ungroup {
  --fa: "";
}

.fa-poop {
  --fa: "";
}

.fa-location-pin, .fa-map-marker {
  --fa: "";
}

.fa-kaaba {
  --fa: "";
}

.fa-toilet-paper {
  --fa: "";
}

.fa-helmet-safety, .fa-hard-hat, .fa-hat-hard {
  --fa: "";
}

.fa-eject {
  --fa: "";
}

.fa-circle-right, .fa-arrow-alt-circle-right {
  --fa: "";
}

.fa-plane-circle-check {
  --fa: "";
}

.fa-face-rolling-eyes, .fa-meh-rolling-eyes {
  --fa: "";
}

.fa-object-group {
  --fa: "";
}

.fa-chart-line, .fa-line-chart {
  --fa: "";
}

.fa-mask-ventilator {
  --fa: "";
}

.fa-arrow-right {
  --fa: "";
}

.fa-signs-post, .fa-map-signs {
  --fa: "";
}

.fa-cash-register {
  --fa: "";
}

.fa-person-circle-question {
  --fa: "";
}

.fa-h {
  --fa: "H";
}

.fa-tarp {
  --fa: "";
}

.fa-screwdriver-wrench, .fa-tools {
  --fa: "";
}

.fa-arrows-to-eye {
  --fa: "";
}

.fa-plug-circle-bolt {
  --fa: "";
}

.fa-heart {
  --fa: "";
}

.fa-mars-and-venus {
  --fa: "";
}

.fa-house-user, .fa-home-user {
  --fa: "";
}

.fa-dumpster-fire {
  --fa: "";
}

.fa-house-crack {
  --fa: "";
}

.fa-martini-glass-citrus, .fa-cocktail {
  --fa: "";
}

.fa-face-surprise, .fa-surprise {
  --fa: "";
}

.fa-bottle-water {
  --fa: "";
}

.fa-circle-pause, .fa-pause-circle {
  --fa: "";
}

.fa-toilet-paper-slash {
  --fa: "";
}

.fa-apple-whole, .fa-apple-alt {
  --fa: "";
}

.fa-kitchen-set {
  --fa: "";
}

.fa-r {
  --fa: "R";
}

.fa-temperature-quarter, .fa-temperature-1, .fa-thermometer-1, .fa-thermometer-quarter {
  --fa: "";
}

.fa-cube {
  --fa: "";
}

.fa-bitcoin-sign {
  --fa: "";
}

.fa-shield-dog {
  --fa: "";
}

.fa-solar-panel {
  --fa: "";
}

.fa-lock-open {
  --fa: "";
}

.fa-elevator {
  --fa: "";
}

.fa-money-bill-transfer {
  --fa: "";
}

.fa-money-bill-trend-up {
  --fa: "";
}

.fa-house-flood-water-circle-arrow-right {
  --fa: "";
}

.fa-square-poll-horizontal, .fa-poll-h {
  --fa: "";
}

.fa-circle {
  --fa: "";
}

.fa-backward-fast, .fa-fast-backward {
  --fa: "";
}

.fa-recycle {
  --fa: "";
}

.fa-user-astronaut {
  --fa: "";
}

.fa-plane-slash {
  --fa: "";
}

.fa-trademark {
  --fa: "";
}

.fa-basketball, .fa-basketball-ball {
  --fa: "";
}

.fa-satellite-dish {
  --fa: "";
}

.fa-circle-up, .fa-arrow-alt-circle-up {
  --fa: "";
}

.fa-mobile-screen-button, .fa-mobile-alt {
  --fa: "";
}

.fa-volume-high, .fa-volume-up {
  --fa: "";
}

.fa-users-rays {
  --fa: "";
}

.fa-wallet {
  --fa: "";
}

.fa-clipboard-check {
  --fa: "";
}

.fa-file-audio {
  --fa: "";
}

.fa-burger, .fa-hamburger {
  --fa: "";
}

.fa-wrench {
  --fa: "";
}

.fa-bugs {
  --fa: "";
}

.fa-rupee-sign, .fa-rupee {
  --fa: "";
}

.fa-file-image {
  --fa: "";
}

.fa-circle-question, .fa-question-circle {
  --fa: "";
}

.fa-plane-departure {
  --fa: "";
}

.fa-handshake-slash {
  --fa: "";
}

.fa-book-bookmark {
  --fa: "";
}

.fa-code-branch {
  --fa: "";
}

.fa-hat-cowboy {
  --fa: "";
}

.fa-bridge {
  --fa: "";
}

.fa-phone-flip, .fa-phone-alt {
  --fa: "";
}

.fa-truck-front {
  --fa: "";
}

.fa-cat {
  --fa: "";
}

.fa-anchor-circle-exclamation {
  --fa: "";
}

.fa-truck-field {
  --fa: "";
}

.fa-route {
  --fa: "";
}

.fa-clipboard-question {
  --fa: "";
}

.fa-panorama {
  --fa: "";
}

.fa-comment-medical {
  --fa: "";
}

.fa-teeth-open {
  --fa: "";
}

.fa-file-circle-minus {
  --fa: "";
}

.fa-tags {
  --fa: "";
}

.fa-wine-glass {
  --fa: "";
}

.fa-forward-fast, .fa-fast-forward {
  --fa: "";
}

.fa-face-meh-blank, .fa-meh-blank {
  --fa: "";
}

.fa-square-parking, .fa-parking {
  --fa: "";
}

.fa-house-signal {
  --fa: "";
}

.fa-bars-progress, .fa-tasks-alt {
  --fa: "";
}

.fa-faucet-drip {
  --fa: "";
}

.fa-cart-flatbed, .fa-dolly-flatbed {
  --fa: "";
}

.fa-ban-smoking, .fa-smoking-ban {
  --fa: "";
}

.fa-terminal {
  --fa: "";
}

.fa-mobile-button {
  --fa: "";
}

.fa-house-medical-flag {
  --fa: "";
}

.fa-basket-shopping, .fa-shopping-basket {
  --fa: "";
}

.fa-tape {
  --fa: "";
}

.fa-bus-simple, .fa-bus-alt {
  --fa: "";
}

.fa-eye {
  --fa: "";
}

.fa-face-sad-cry, .fa-sad-cry {
  --fa: "";
}

.fa-audio-description {
  --fa: "";
}

.fa-person-military-to-person {
  --fa: "";
}

.fa-file-shield {
  --fa: "";
}

.fa-user-slash {
  --fa: "";
}

.fa-pen {
  --fa: "";
}

.fa-tower-observation {
  --fa: "";
}

.fa-file-code {
  --fa: "";
}

.fa-signal, .fa-signal-5, .fa-signal-perfect {
  --fa: "";
}

.fa-bus {
  --fa: "";
}

.fa-heart-circle-xmark {
  --fa: "";
}

.fa-house-chimney, .fa-home-lg {
  --fa: "";
}

.fa-window-maximize {
  --fa: "";
}

.fa-face-frown, .fa-frown {
  --fa: "";
}

.fa-prescription {
  --fa: "";
}

.fa-shop, .fa-store-alt {
  --fa: "";
}

.fa-floppy-disk, .fa-save {
  --fa: "";
}

.fa-vihara {
  --fa: "";
}

.fa-scale-unbalanced, .fa-balance-scale-left {
  --fa: "";
}

.fa-sort-up, .fa-sort-asc {
  --fa: "";
}

.fa-comment-dots, .fa-commenting {
  --fa: "";
}

.fa-plant-wilt {
  --fa: "";
}

.fa-diamond {
  --fa: "";
}

.fa-face-grin-squint, .fa-grin-squint {
  --fa: "";
}

.fa-hand-holding-dollar, .fa-hand-holding-usd {
  --fa: "";
}

.fa-chart-diagram {
  --fa: "";
}

.fa-bacterium {
  --fa: "";
}

.fa-hand-pointer {
  --fa: "";
}

.fa-drum-steelpan {
  --fa: "";
}

.fa-hand-scissors {
  --fa: "";
}

.fa-hands-praying, .fa-praying-hands {
  --fa: "";
}

.fa-arrow-rotate-right, .fa-arrow-right-rotate, .fa-arrow-rotate-forward, .fa-redo {
  --fa: "";
}

.fa-biohazard {
  --fa: "";
}

.fa-location-crosshairs, .fa-location {
  --fa: "";
}

.fa-mars-double {
  --fa: "";
}

.fa-child-dress {
  --fa: "";
}

.fa-users-between-lines {
  --fa: "";
}

.fa-lungs-virus {
  --fa: "";
}

.fa-face-grin-tears, .fa-grin-tears {
  --fa: "";
}

.fa-phone {
  --fa: "";
}

.fa-calendar-xmark, .fa-calendar-times {
  --fa: "";
}

.fa-child-reaching {
  --fa: "";
}

.fa-head-side-virus {
  --fa: "";
}

.fa-user-gear, .fa-user-cog {
  --fa: "";
}

.fa-arrow-up-1-9, .fa-sort-numeric-up {
  --fa: "";
}

.fa-door-closed {
  --fa: "";
}

.fa-shield-virus {
  --fa: "";
}

.fa-dice-six {
  --fa: "";
}

.fa-mosquito-net {
  --fa: "";
}

.fa-file-fragment {
  --fa: "";
}

.fa-bridge-water {
  --fa: "";
}

.fa-person-booth {
  --fa: "";
}

.fa-text-width {
  --fa: "";
}

.fa-hat-wizard {
  --fa: "";
}

.fa-pen-fancy {
  --fa: "";
}

.fa-person-digging, .fa-digging {
  --fa: "";
}

.fa-trash {
  --fa: "";
}

.fa-gauge-simple, .fa-gauge-simple-med, .fa-tachometer-average {
  --fa: "";
}

.fa-book-medical {
  --fa: "";
}

.fa-poo {
  --fa: "";
}

.fa-quote-right, .fa-quote-right-alt {
  --fa: "";
}

.fa-shirt, .fa-t-shirt, .fa-tshirt {
  --fa: "";
}

.fa-cubes {
  --fa: "";
}

.fa-divide {
  --fa: "";
}

.fa-tenge-sign, .fa-tenge {
  --fa: "";
}

.fa-headphones {
  --fa: "";
}

.fa-hands-holding {
  --fa: "";
}

.fa-hands-clapping {
  --fa: "";
}

.fa-republican {
  --fa: "";
}

.fa-arrow-left {
  --fa: "";
}

.fa-person-circle-xmark {
  --fa: "";
}

.fa-ruler {
  --fa: "";
}

.fa-align-left {
  --fa: "";
}

.fa-dice-d6 {
  --fa: "";
}

.fa-restroom {
  --fa: "";
}

.fa-j {
  --fa: "J";
}

.fa-users-viewfinder {
  --fa: "";
}

.fa-file-video {
  --fa: "";
}

.fa-up-right-from-square, .fa-external-link-alt {
  --fa: "";
}

.fa-table-cells, .fa-th {
  --fa: "";
}

.fa-file-pdf {
  --fa: "";
}

.fa-book-bible, .fa-bible {
  --fa: "";
}

.fa-o {
  --fa: "O";
}

.fa-suitcase-medical, .fa-medkit {
  --fa: "";
}

.fa-user-secret {
  --fa: "";
}

.fa-otter {
  --fa: "";
}

.fa-person-dress, .fa-female {
  --fa: "";
}

.fa-comment-dollar {
  --fa: "";
}

.fa-business-time, .fa-briefcase-clock {
  --fa: "";
}

.fa-table-cells-large, .fa-th-large {
  --fa: "";
}

.fa-book-tanakh, .fa-tanakh {
  --fa: "";
}

.fa-phone-volume, .fa-volume-control-phone {
  --fa: "";
}

.fa-hat-cowboy-side {
  --fa: "";
}

.fa-clipboard-user {
  --fa: "";
}

.fa-child {
  --fa: "";
}

.fa-lira-sign {
  --fa: "";
}

.fa-satellite {
  --fa: "";
}

.fa-plane-lock {
  --fa: "";
}

.fa-tag {
  --fa: "";
}

.fa-comment {
  --fa: "";
}

.fa-cake-candles, .fa-birthday-cake, .fa-cake {
  --fa: "";
}

.fa-envelope {
  --fa: "";
}

.fa-angles-up, .fa-angle-double-up {
  --fa: "";
}

.fa-paperclip {
  --fa: "";
}

.fa-arrow-right-to-city {
  --fa: "";
}

.fa-ribbon {
  --fa: "";
}

.fa-lungs {
  --fa: "";
}

.fa-arrow-up-9-1, .fa-sort-numeric-up-alt {
  --fa: "";
}

.fa-litecoin-sign {
  --fa: "";
}

.fa-border-none {
  --fa: "";
}

.fa-circle-nodes {
  --fa: "";
}

.fa-parachute-box {
  --fa: "";
}

.fa-indent {
  --fa: "";
}

.fa-truck-field-un {
  --fa: "";
}

.fa-hourglass, .fa-hourglass-empty {
  --fa: "";
}

.fa-mountain {
  --fa: "";
}

.fa-user-doctor, .fa-user-md {
  --fa: "";
}

.fa-circle-info, .fa-info-circle {
  --fa: "";
}

.fa-cloud-meatball {
  --fa: "";
}

.fa-camera, .fa-camera-alt {
  --fa: "";
}

.fa-square-virus {
  --fa: "";
}

.fa-meteor {
  --fa: "";
}

.fa-car-on {
  --fa: "";
}

.fa-sleigh {
  --fa: "";
}

.fa-arrow-down-1-9, .fa-sort-numeric-asc, .fa-sort-numeric-down {
  --fa: "";
}

.fa-hand-holding-droplet, .fa-hand-holding-water {
  --fa: "";
}

.fa-water {
  --fa: "";
}

.fa-calendar-check {
  --fa: "";
}

.fa-braille {
  --fa: "";
}

.fa-prescription-bottle-medical, .fa-prescription-bottle-alt {
  --fa: "";
}

.fa-landmark {
  --fa: "";
}

.fa-truck {
  --fa: "";
}

.fa-crosshairs {
  --fa: "";
}

.fa-person-cane {
  --fa: "";
}

.fa-tent {
  --fa: "";
}

.fa-vest-patches {
  --fa: "";
}

.fa-check-double {
  --fa: "";
}

.fa-arrow-down-a-z, .fa-sort-alpha-asc, .fa-sort-alpha-down {
  --fa: "";
}

.fa-money-bill-wheat {
  --fa: "";
}

.fa-cookie {
  --fa: "";
}

.fa-arrow-rotate-left, .fa-arrow-left-rotate, .fa-arrow-rotate-back, .fa-arrow-rotate-backward, .fa-undo {
  --fa: "";
}

.fa-hard-drive, .fa-hdd {
  --fa: "";
}

.fa-face-grin-squint-tears, .fa-grin-squint-tears {
  --fa: "";
}

.fa-dumbbell {
  --fa: "";
}

.fa-rectangle-list, .fa-list-alt {
  --fa: "";
}

.fa-tarp-droplet {
  --fa: "";
}

.fa-house-medical-circle-check {
  --fa: "";
}

.fa-person-skiing-nordic, .fa-skiing-nordic {
  --fa: "";
}

.fa-calendar-plus {
  --fa: "";
}

.fa-plane-arrival {
  --fa: "";
}

.fa-circle-left, .fa-arrow-alt-circle-left {
  --fa: "";
}

.fa-train-subway, .fa-subway {
  --fa: "";
}

.fa-chart-gantt {
  --fa: "";
}

.fa-indian-rupee-sign, .fa-indian-rupee, .fa-inr {
  --fa: "";
}

.fa-crop-simple, .fa-crop-alt {
  --fa: "";
}

.fa-money-bill-1, .fa-money-bill-alt {
  --fa: "";
}

.fa-left-long, .fa-long-arrow-alt-left {
  --fa: "";
}

.fa-dna {
  --fa: "";
}

.fa-virus-slash {
  --fa: "";
}

.fa-minus, .fa-subtract {
  --fa: "";
}

.fa-chess {
  --fa: "";
}

.fa-arrow-left-long, .fa-long-arrow-left {
  --fa: "";
}

.fa-plug-circle-check {
  --fa: "";
}

.fa-street-view {
  --fa: "";
}

.fa-franc-sign {
  --fa: "";
}

.fa-volume-off {
  --fa: "";
}

.fa-hands-asl-interpreting, .fa-american-sign-language-interpreting, .fa-asl-interpreting, .fa-hands-american-sign-language-interpreting {
  --fa: "";
}

.fa-gear, .fa-cog {
  --fa: "";
}

.fa-droplet-slash, .fa-tint-slash {
  --fa: "";
}

.fa-mosque {
  --fa: "";
}

.fa-mosquito {
  --fa: "";
}

.fa-star-of-david {
  --fa: "";
}

.fa-person-military-rifle {
  --fa: "";
}

.fa-cart-shopping, .fa-shopping-cart {
  --fa: "";
}

.fa-vials {
  --fa: "";
}

.fa-plug-circle-plus {
  --fa: "";
}

.fa-place-of-worship {
  --fa: "";
}

.fa-grip-vertical {
  --fa: "";
}

.fa-hexagon-nodes {
  --fa: "";
}

.fa-arrow-turn-up, .fa-level-up {
  --fa: "";
}

.fa-u {
  --fa: "U";
}

.fa-square-root-variable, .fa-square-root-alt {
  --fa: "";
}

.fa-clock, .fa-clock-four {
  --fa: "";
}

.fa-backward-step, .fa-step-backward {
  --fa: "";
}

.fa-pallet {
  --fa: "";
}

.fa-faucet {
  --fa: "";
}

.fa-baseball-bat-ball {
  --fa: "";
}

.fa-s {
  --fa: "S";
}

.fa-timeline {
  --fa: "";
}

.fa-keyboard {
  --fa: "";
}

.fa-caret-down {
  --fa: "";
}

.fa-house-chimney-medical, .fa-clinic-medical {
  --fa: "";
}

.fa-temperature-three-quarters, .fa-temperature-3, .fa-thermometer-3, .fa-thermometer-three-quarters {
  --fa: "";
}

.fa-mobile-screen, .fa-mobile-android-alt {
  --fa: "";
}

.fa-plane-up {
  --fa: "";
}

.fa-piggy-bank {
  --fa: "";
}

.fa-battery-half, .fa-battery-3 {
  --fa: "";
}

.fa-mountain-city {
  --fa: "";
}

.fa-coins {
  --fa: "";
}

.fa-khanda {
  --fa: "";
}

.fa-sliders, .fa-sliders-h {
  --fa: "";
}

.fa-folder-tree {
  --fa: "";
}

.fa-network-wired {
  --fa: "";
}

.fa-map-pin {
  --fa: "";
}

.fa-hamsa {
  --fa: "";
}

.fa-cent-sign {
  --fa: "";
}

.fa-flask {
  --fa: "";
}

.fa-person-pregnant {
  --fa: "";
}

.fa-wand-sparkles {
  --fa: "";
}

.fa-ellipsis-vertical, .fa-ellipsis-v {
  --fa: "";
}

.fa-ticket {
  --fa: "";
}

.fa-power-off {
  --fa: "";
}

.fa-right-long, .fa-long-arrow-alt-right {
  --fa: "";
}

.fa-flag-usa {
  --fa: "";
}

.fa-laptop-file {
  --fa: "";
}

.fa-tty, .fa-teletype {
  --fa: "";
}

.fa-diagram-next {
  --fa: "";
}

.fa-person-rifle {
  --fa: "";
}

.fa-house-medical-circle-exclamation {
  --fa: "";
}

.fa-closed-captioning {
  --fa: "";
}

.fa-person-hiking, .fa-hiking {
  --fa: "";
}

.fa-venus-double {
  --fa: "";
}

.fa-images {
  --fa: "";
}

.fa-calculator {
  --fa: "";
}

.fa-people-pulling {
  --fa: "";
}

.fa-n {
  --fa: "N";
}

.fa-cable-car, .fa-tram {
  --fa: "";
}

.fa-cloud-rain {
  --fa: "";
}

.fa-building-circle-xmark {
  --fa: "";
}

.fa-ship {
  --fa: "";
}

.fa-arrows-down-to-line {
  --fa: "";
}

.fa-download {
  --fa: "";
}

.fa-face-grin, .fa-grin {
  --fa: "";
}

.fa-delete-left, .fa-backspace {
  --fa: "";
}

.fa-eye-dropper, .fa-eye-dropper-empty, .fa-eyedropper {
  --fa: "";
}

.fa-file-circle-check {
  --fa: "";
}

.fa-forward {
  --fa: "";
}

.fa-mobile, .fa-mobile-android, .fa-mobile-phone {
  --fa: "";
}

.fa-face-meh, .fa-meh {
  --fa: "";
}

.fa-align-center {
  --fa: "";
}

.fa-book-skull, .fa-book-dead {
  --fa: "";
}

.fa-id-card, .fa-drivers-license {
  --fa: "";
}

.fa-outdent, .fa-dedent {
  --fa: "";
}

.fa-heart-circle-exclamation {
  --fa: "";
}

.fa-house, .fa-home, .fa-home-alt, .fa-home-lg-alt {
  --fa: "";
}

.fa-calendar-week {
  --fa: "";
}

.fa-laptop-medical {
  --fa: "";
}

.fa-b {
  --fa: "B";
}

.fa-file-medical {
  --fa: "";
}

.fa-dice-one {
  --fa: "";
}

.fa-kiwi-bird {
  --fa: "";
}

.fa-arrow-right-arrow-left, .fa-exchange {
  --fa: "";
}

.fa-rotate-right, .fa-redo-alt, .fa-rotate-forward {
  --fa: "";
}

.fa-utensils, .fa-cutlery {
  --fa: "";
}

.fa-arrow-up-wide-short, .fa-sort-amount-up {
  --fa: "";
}

.fa-mill-sign {
  --fa: "";
}

.fa-bowl-rice {
  --fa: "";
}

.fa-skull {
  --fa: "";
}

.fa-tower-broadcast, .fa-broadcast-tower {
  --fa: "";
}

.fa-truck-pickup {
  --fa: "";
}

.fa-up-long, .fa-long-arrow-alt-up {
  --fa: "";
}

.fa-stop {
  --fa: "";
}

.fa-code-merge {
  --fa: "";
}

.fa-upload {
  --fa: "";
}

.fa-hurricane {
  --fa: "";
}

.fa-mound {
  --fa: "";
}

.fa-toilet-portable {
  --fa: "";
}

.fa-compact-disc {
  --fa: "";
}

.fa-file-arrow-down, .fa-file-download {
  --fa: "";
}

.fa-caravan {
  --fa: "";
}

.fa-shield-cat {
  --fa: "";
}

.fa-bolt, .fa-zap {
  --fa: "";
}

.fa-glass-water {
  --fa: "";
}

.fa-oil-well {
  --fa: "";
}

.fa-vault {
  --fa: "";
}

.fa-mars {
  --fa: "";
}

.fa-toilet {
  --fa: "";
}

.fa-plane-circle-xmark {
  --fa: "";
}

.fa-yen-sign, .fa-cny, .fa-jpy, .fa-rmb, .fa-yen {
  --fa: "";
}

.fa-ruble-sign, .fa-rouble, .fa-rub, .fa-ruble {
  --fa: "";
}

.fa-sun {
  --fa: "";
}

.fa-guitar {
  --fa: "";
}

.fa-face-laugh-wink, .fa-laugh-wink {
  --fa: "";
}

.fa-horse-head {
  --fa: "";
}

.fa-bore-hole {
  --fa: "";
}

.fa-industry {
  --fa: "";
}

.fa-circle-down, .fa-arrow-alt-circle-down {
  --fa: "";
}

.fa-arrows-turn-to-dots {
  --fa: "";
}

.fa-florin-sign {
  --fa: "";
}

.fa-arrow-down-short-wide, .fa-sort-amount-desc, .fa-sort-amount-down-alt {
  --fa: "";
}

.fa-less-than {
  --fa: "<";
}

.fa-angle-down {
  --fa: "";
}

.fa-car-tunnel {
  --fa: "";
}

.fa-head-side-cough {
  --fa: "";
}

.fa-grip-lines {
  --fa: "";
}

.fa-thumbs-down {
  --fa: "";
}

.fa-user-lock {
  --fa: "";
}

.fa-arrow-right-long, .fa-long-arrow-right {
  --fa: "";
}

.fa-anchor-circle-xmark {
  --fa: "";
}

.fa-ellipsis, .fa-ellipsis-h {
  --fa: "";
}

.fa-chess-pawn {
  --fa: "";
}

.fa-kit-medical, .fa-first-aid {
  --fa: "";
}

.fa-person-through-window {
  --fa: "";
}

.fa-toolbox {
  --fa: "";
}

.fa-hands-holding-circle {
  --fa: "";
}

.fa-bug {
  --fa: "";
}

.fa-credit-card, .fa-credit-card-alt {
  --fa: "";
}

.fa-car, .fa-automobile {
  --fa: "";
}

.fa-hand-holding-hand {
  --fa: "";
}

.fa-book-open-reader, .fa-book-reader {
  --fa: "";
}

.fa-mountain-sun {
  --fa: "";
}

.fa-arrows-left-right-to-line {
  --fa: "";
}

.fa-dice-d20 {
  --fa: "";
}

.fa-truck-droplet {
  --fa: "";
}

.fa-file-circle-xmark {
  --fa: "";
}

.fa-temperature-arrow-up, .fa-temperature-up {
  --fa: "";
}

.fa-medal {
  --fa: "";
}

.fa-bed {
  --fa: "";
}

.fa-square-h, .fa-h-square {
  --fa: "";
}

.fa-podcast {
  --fa: "";
}

.fa-temperature-full, .fa-temperature-4, .fa-thermometer-4, .fa-thermometer-full {
  --fa: "";
}

.fa-bell {
  --fa: "";
}

.fa-superscript {
  --fa: "";
}

.fa-plug-circle-xmark {
  --fa: "";
}

.fa-star-of-life {
  --fa: "";
}

.fa-phone-slash {
  --fa: "";
}

.fa-paint-roller {
  --fa: "";
}

.fa-handshake-angle, .fa-hands-helping {
  --fa: "";
}

.fa-location-dot, .fa-map-marker-alt {
  --fa: "";
}

.fa-file {
  --fa: "";
}

.fa-greater-than {
  --fa: ">";
}

.fa-person-swimming, .fa-swimmer {
  --fa: "";
}

.fa-arrow-down {
  --fa: "";
}

.fa-droplet, .fa-tint {
  --fa: "";
}

.fa-eraser {
  --fa: "";
}

.fa-earth-americas, .fa-earth, .fa-earth-america, .fa-globe-americas {
  --fa: "";
}

.fa-person-burst {
  --fa: "";
}

.fa-dove {
  --fa: "";
}

.fa-battery-empty, .fa-battery-0 {
  --fa: "";
}

.fa-socks {
  --fa: "";
}

.fa-inbox {
  --fa: "";
}

.fa-section {
  --fa: "";
}

.fa-gauge-high, .fa-tachometer-alt, .fa-tachometer-alt-fast {
  --fa: "";
}

.fa-envelope-open-text {
  --fa: "";
}

.fa-hospital, .fa-hospital-alt, .fa-hospital-wide {
  --fa: "";
}

.fa-wine-bottle {
  --fa: "";
}

.fa-chess-rook {
  --fa: "";
}

.fa-bars-staggered, .fa-reorder, .fa-stream {
  --fa: "";
}

.fa-dharmachakra {
  --fa: "";
}

.fa-hotdog {
  --fa: "";
}

.fa-person-walking-with-cane, .fa-blind {
  --fa: "";
}

.fa-drum {
  --fa: "";
}

.fa-ice-cream {
  --fa: "";
}

.fa-heart-circle-bolt {
  --fa: "";
}

.fa-fax {
  --fa: "";
}

.fa-paragraph {
  --fa: "";
}

.fa-check-to-slot, .fa-vote-yea {
  --fa: "";
}

.fa-star-half {
  --fa: "";
}

.fa-boxes-stacked, .fa-boxes, .fa-boxes-alt {
  --fa: "";
}

.fa-link, .fa-chain {
  --fa: "";
}

.fa-ear-listen, .fa-assistive-listening-systems {
  --fa: "";
}

.fa-tree-city {
  --fa: "";
}

.fa-play {
  --fa: "";
}

.fa-font {
  --fa: "";
}

.fa-table-cells-row-lock {
  --fa: "";
}

.fa-rupiah-sign {
  --fa: "";
}

.fa-magnifying-glass, .fa-search {
  --fa: "";
}

.fa-table-tennis-paddle-ball, .fa-ping-pong-paddle-ball, .fa-table-tennis {
  --fa: "";
}

.fa-person-dots-from-line, .fa-diagnoses {
  --fa: "";
}

.fa-trash-can-arrow-up, .fa-trash-restore-alt {
  --fa: "";
}

.fa-naira-sign {
  --fa: "";
}

.fa-cart-arrow-down {
  --fa: "";
}

.fa-walkie-talkie {
  --fa: "";
}

.fa-file-pen, .fa-file-edit {
  --fa: "";
}

.fa-receipt {
  --fa: "";
}

.fa-square-pen, .fa-pen-square, .fa-pencil-square {
  --fa: "";
}

.fa-suitcase-rolling {
  --fa: "";
}

.fa-person-circle-exclamation {
  --fa: "";
}

.fa-chevron-down {
  --fa: "";
}

.fa-battery-full, .fa-battery, .fa-battery-5 {
  --fa: "";
}

.fa-skull-crossbones {
  --fa: "";
}

.fa-code-compare {
  --fa: "";
}

.fa-list-ul, .fa-list-dots {
  --fa: "";
}

.fa-school-lock {
  --fa: "";
}

.fa-tower-cell {
  --fa: "";
}

.fa-down-long, .fa-long-arrow-alt-down {
  --fa: "";
}

.fa-ranking-star {
  --fa: "";
}

.fa-chess-king {
  --fa: "";
}

.fa-person-harassing {
  --fa: "";
}

.fa-brazilian-real-sign {
  --fa: "";
}

.fa-landmark-dome, .fa-landmark-alt {
  --fa: "";
}

.fa-arrow-up {
  --fa: "";
}

.fa-tv, .fa-television, .fa-tv-alt {
  --fa: "";
}

.fa-shrimp {
  --fa: "";
}

.fa-list-check, .fa-tasks {
  --fa: "";
}

.fa-jug-detergent {
  --fa: "";
}

.fa-circle-user, .fa-user-circle {
  --fa: "";
}

.fa-user-shield {
  --fa: "";
}

.fa-wind {
  --fa: "";
}

.fa-car-burst, .fa-car-crash {
  --fa: "";
}

.fa-y {
  --fa: "Y";
}

.fa-person-snowboarding, .fa-snowboarding {
  --fa: "";
}

.fa-truck-fast, .fa-shipping-fast {
  --fa: "";
}

.fa-fish {
  --fa: "";
}

.fa-user-graduate {
  --fa: "";
}

.fa-circle-half-stroke, .fa-adjust {
  --fa: "";
}

.fa-clapperboard {
  --fa: "";
}

.fa-circle-radiation, .fa-radiation-alt {
  --fa: "";
}

.fa-baseball, .fa-baseball-ball {
  --fa: "";
}

.fa-jet-fighter-up {
  --fa: "";
}

.fa-diagram-project, .fa-project-diagram {
  --fa: "";
}

.fa-copy {
  --fa: "";
}

.fa-volume-xmark, .fa-volume-mute, .fa-volume-times {
  --fa: "";
}

.fa-hand-sparkles {
  --fa: "";
}

.fa-grip, .fa-grip-horizontal {
  --fa: "";
}

.fa-share-from-square, .fa-share-square {
  --fa: "";
}

.fa-child-combatant, .fa-child-rifle {
  --fa: "";
}

.fa-gun {
  --fa: "";
}

.fa-square-phone, .fa-phone-square {
  --fa: "";
}

.fa-plus, .fa-add {
  --fa: "+";
}

.fa-expand {
  --fa: "";
}

.fa-computer {
  --fa: "";
}

.fa-xmark, .fa-close, .fa-multiply, .fa-remove, .fa-times {
  --fa: "";
}

.fa-arrows-up-down-left-right, .fa-arrows {
  --fa: "";
}

.fa-chalkboard-user, .fa-chalkboard-teacher {
  --fa: "";
}

.fa-peso-sign {
  --fa: "";
}

.fa-building-shield {
  --fa: "";
}

.fa-baby {
  --fa: "";
}

.fa-users-line {
  --fa: "";
}

.fa-quote-left, .fa-quote-left-alt {
  --fa: "";
}

.fa-tractor {
  --fa: "";
}

.fa-trash-arrow-up, .fa-trash-restore {
  --fa: "";
}

.fa-arrow-down-up-lock {
  --fa: "";
}

.fa-lines-leaning {
  --fa: "";
}

.fa-ruler-combined {
  --fa: "";
}

.fa-copyright {
  --fa: "";
}

.fa-equals {
  --fa: "=";
}

.fa-blender {
  --fa: "";
}

.fa-teeth {
  --fa: "";
}

.fa-shekel-sign, .fa-ils, .fa-shekel, .fa-sheqel, .fa-sheqel-sign {
  --fa: "";
}

.fa-map {
  --fa: "";
}

.fa-rocket {
  --fa: "";
}

.fa-photo-film, .fa-photo-video {
  --fa: "";
}

.fa-folder-minus {
  --fa: "";
}

.fa-hexagon-nodes-bolt {
  --fa: "";
}

.fa-store {
  --fa: "";
}

.fa-arrow-trend-up {
  --fa: "";
}

.fa-plug-circle-minus {
  --fa: "";
}

.fa-sign-hanging, .fa-sign {
  --fa: "";
}

.fa-bezier-curve {
  --fa: "";
}

.fa-bell-slash {
  --fa: "";
}

.fa-tablet, .fa-tablet-android {
  --fa: "";
}

.fa-school-flag {
  --fa: "";
}

.fa-fill {
  --fa: "";
}

.fa-angle-up {
  --fa: "";
}

.fa-drumstick-bite {
  --fa: "";
}

.fa-holly-berry {
  --fa: "";
}

.fa-chevron-left {
  --fa: "";
}

.fa-bacteria {
  --fa: "";
}

.fa-hand-lizard {
  --fa: "";
}

.fa-notdef {
  --fa: "";
}

.fa-disease {
  --fa: "";
}

.fa-briefcase-medical {
  --fa: "";
}

.fa-genderless {
  --fa: "";
}

.fa-chevron-right {
  --fa: "";
}

.fa-retweet {
  --fa: "";
}

.fa-car-rear, .fa-car-alt {
  --fa: "";
}

.fa-pump-soap {
  --fa: "";
}

.fa-video-slash {
  --fa: "";
}

.fa-battery-quarter, .fa-battery-2 {
  --fa: "";
}

.fa-radio {
  --fa: "";
}

.fa-baby-carriage, .fa-carriage-baby {
  --fa: "";
}

.fa-traffic-light {
  --fa: "";
}

.fa-thermometer {
  --fa: "";
}

.fa-vr-cardboard {
  --fa: "";
}

.fa-hand-middle-finger {
  --fa: "";
}

.fa-percent, .fa-percentage {
  --fa: "%";
}

.fa-truck-moving {
  --fa: "";
}

.fa-glass-water-droplet {
  --fa: "";
}

.fa-display {
  --fa: "";
}

.fa-face-smile, .fa-smile {
  --fa: "";
}

.fa-thumbtack, .fa-thumb-tack {
  --fa: "";
}

.fa-trophy {
  --fa: "";
}

.fa-person-praying, .fa-pray {
  --fa: "";
}

.fa-hammer {
  --fa: "";
}

.fa-hand-peace {
  --fa: "";
}

.fa-rotate, .fa-sync-alt {
  --fa: "";
}

.fa-spinner {
  --fa: "";
}

.fa-robot {
  --fa: "";
}

.fa-peace {
  --fa: "";
}

.fa-gears, .fa-cogs {
  --fa: "";
}

.fa-warehouse {
  --fa: "";
}

.fa-arrow-up-right-dots {
  --fa: "";
}

.fa-splotch {
  --fa: "";
}

.fa-face-grin-hearts, .fa-grin-hearts {
  --fa: "";
}

.fa-dice-four {
  --fa: "";
}

.fa-sim-card {
  --fa: "";
}

.fa-transgender, .fa-transgender-alt {
  --fa: "";
}

.fa-mercury {
  --fa: "";
}

.fa-arrow-turn-down, .fa-level-down {
  --fa: "";
}

.fa-person-falling-burst {
  --fa: "";
}

.fa-award {
  --fa: "";
}

.fa-ticket-simple, .fa-ticket-alt {
  --fa: "";
}

.fa-building {
  --fa: "";
}

.fa-angles-left, .fa-angle-double-left {
  --fa: "";
}

.fa-qrcode {
  --fa: "";
}

.fa-clock-rotate-left, .fa-history {
  --fa: "";
}

.fa-face-grin-beam-sweat, .fa-grin-beam-sweat {
  --fa: "";
}

.fa-file-export, .fa-arrow-right-from-file {
  --fa: "";
}

.fa-shield, .fa-shield-blank {
  --fa: "";
}

.fa-arrow-up-short-wide, .fa-sort-amount-up-alt {
  --fa: "";
}

.fa-comment-nodes {
  --fa: "";
}

.fa-house-medical {
  --fa: "";
}

.fa-golf-ball-tee, .fa-golf-ball {
  --fa: "";
}

.fa-circle-chevron-left, .fa-chevron-circle-left {
  --fa: "";
}

.fa-house-chimney-window {
  --fa: "";
}

.fa-pen-nib {
  --fa: "";
}

.fa-tent-arrow-turn-left {
  --fa: "";
}

.fa-tents {
  --fa: "";
}

.fa-wand-magic, .fa-magic {
  --fa: "";
}

.fa-dog {
  --fa: "";
}

.fa-carrot {
  --fa: "";
}

.fa-moon {
  --fa: "";
}

.fa-wine-glass-empty, .fa-wine-glass-alt {
  --fa: "";
}

.fa-cheese {
  --fa: "";
}

.fa-yin-yang {
  --fa: "";
}

.fa-music {
  --fa: "";
}

.fa-code-commit {
  --fa: "";
}

.fa-temperature-low {
  --fa: "";
}

.fa-person-biking, .fa-biking {
  --fa: "";
}

.fa-broom {
  --fa: "";
}

.fa-shield-heart {
  --fa: "";
}

.fa-gopuram {
  --fa: "";
}

.fa-earth-oceania, .fa-globe-oceania {
  --fa: "";
}

.fa-square-xmark, .fa-times-square, .fa-xmark-square {
  --fa: "";
}

.fa-hashtag {
  --fa: "#";
}

.fa-up-right-and-down-left-from-center, .fa-expand-alt {
  --fa: "";
}

.fa-oil-can {
  --fa: "";
}

.fa-t {
  --fa: "T";
}

.fa-hippo {
  --fa: "";
}

.fa-chart-column {
  --fa: "";
}

.fa-infinity {
  --fa: "";
}

.fa-vial-circle-check {
  --fa: "";
}

.fa-person-arrow-down-to-line {
  --fa: "";
}

.fa-voicemail {
  --fa: "";
}

.fa-fan {
  --fa: "";
}

.fa-person-walking-luggage {
  --fa: "";
}

.fa-up-down, .fa-arrows-alt-v {
  --fa: "";
}

.fa-cloud-moon-rain {
  --fa: "";
}

.fa-calendar {
  --fa: "";
}

.fa-trailer {
  --fa: "";
}

.fa-bahai, .fa-haykal {
  --fa: "";
}

.fa-sd-card {
  --fa: "";
}

.fa-dragon {
  --fa: "";
}

.fa-shoe-prints {
  --fa: "";
}

.fa-circle-plus, .fa-plus-circle {
  --fa: "";
}

.fa-face-grin-tongue-wink, .fa-grin-tongue-wink {
  --fa: "";
}

.fa-hand-holding {
  --fa: "";
}

.fa-plug-circle-exclamation {
  --fa: "";
}

.fa-link-slash, .fa-chain-broken, .fa-chain-slash, .fa-unlink {
  --fa: "";
}

.fa-clone {
  --fa: "";
}

.fa-person-walking-arrow-loop-left {
  --fa: "";
}

.fa-arrow-up-z-a, .fa-sort-alpha-up-alt {
  --fa: "";
}

.fa-fire-flame-curved, .fa-fire-alt {
  --fa: "";
}

.fa-tornado {
  --fa: "";
}

.fa-file-circle-plus {
  --fa: "";
}

.fa-book-quran, .fa-quran {
  --fa: "";
}

.fa-anchor {
  --fa: "";
}

.fa-border-all {
  --fa: "";
}

.fa-face-angry, .fa-angry {
  --fa: "";
}

.fa-cookie-bite {
  --fa: "";
}

.fa-arrow-trend-down {
  --fa: "";
}

.fa-rss, .fa-feed {
  --fa: "";
}

.fa-draw-polygon {
  --fa: "";
}

.fa-scale-balanced, .fa-balance-scale {
  --fa: "";
}

.fa-gauge-simple-high, .fa-tachometer, .fa-tachometer-fast {
  --fa: "";
}

.fa-shower {
  --fa: "";
}

.fa-desktop, .fa-desktop-alt {
  --fa: "";
}

.fa-m {
  --fa: "M";
}

.fa-table-list, .fa-th-list {
  --fa: "";
}

.fa-comment-sms, .fa-sms {
  --fa: "";
}

.fa-book {
  --fa: "";
}

.fa-user-plus {
  --fa: "";
}

.fa-check {
  --fa: "";
}

.fa-battery-three-quarters, .fa-battery-4 {
  --fa: "";
}

.fa-house-circle-check {
  --fa: "";
}

.fa-angle-left {
  --fa: "";
}

.fa-diagram-successor {
  --fa: "";
}

.fa-truck-arrow-right {
  --fa: "";
}

.fa-arrows-split-up-and-left {
  --fa: "";
}

.fa-hand-fist, .fa-fist-raised {
  --fa: "";
}

.fa-cloud-moon {
  --fa: "";
}

.fa-briefcase {
  --fa: "";
}

.fa-person-falling {
  --fa: "";
}

.fa-image-portrait, .fa-portrait {
  --fa: "";
}

.fa-user-tag {
  --fa: "";
}

.fa-rug {
  --fa: "";
}

.fa-earth-europe, .fa-globe-europe {
  --fa: "";
}

.fa-cart-flatbed-suitcase, .fa-luggage-cart {
  --fa: "";
}

.fa-rectangle-xmark, .fa-rectangle-times, .fa-times-rectangle, .fa-window-close {
  --fa: "";
}

.fa-baht-sign {
  --fa: "";
}

.fa-book-open {
  --fa: "";
}

.fa-book-journal-whills, .fa-journal-whills {
  --fa: "";
}

.fa-handcuffs {
  --fa: "";
}

.fa-triangle-exclamation, .fa-exclamation-triangle, .fa-warning {
  --fa: "";
}

.fa-database {
  --fa: "";
}

.fa-share, .fa-mail-forward {
  --fa: "";
}

.fa-bottle-droplet {
  --fa: "";
}

.fa-mask-face {
  --fa: "";
}

.fa-hill-rockslide {
  --fa: "";
}

.fa-right-left, .fa-exchange-alt {
  --fa: "";
}

.fa-paper-plane {
  --fa: "";
}

.fa-road-circle-exclamation {
  --fa: "";
}

.fa-dungeon {
  --fa: "";
}

.fa-align-right {
  --fa: "";
}

.fa-money-bill-1-wave, .fa-money-bill-wave-alt {
  --fa: "";
}

.fa-life-ring {
  --fa: "";
}

.fa-hands, .fa-sign-language, .fa-signing {
  --fa: "";
}

.fa-calendar-day {
  --fa: "";
}

.fa-water-ladder, .fa-ladder-water, .fa-swimming-pool {
  --fa: "";
}

.fa-arrows-up-down, .fa-arrows-v {
  --fa: "";
}

.fa-face-grimace, .fa-grimace {
  --fa: "";
}

.fa-wheelchair-move, .fa-wheelchair-alt {
  --fa: "";
}

.fa-turn-down, .fa-level-down-alt {
  --fa: "";
}

.fa-person-walking-arrow-right {
  --fa: "";
}

.fa-square-envelope, .fa-envelope-square {
  --fa: "";
}

.fa-dice {
  --fa: "";
}

.fa-bowling-ball {
  --fa: "";
}

.fa-brain {
  --fa: "";
}

.fa-bandage, .fa-band-aid {
  --fa: "";
}

.fa-calendar-minus {
  --fa: "";
}

.fa-circle-xmark, .fa-times-circle, .fa-xmark-circle {
  --fa: "";
}

.fa-gifts {
  --fa: "";
}

.fa-hotel {
  --fa: "";
}

.fa-earth-asia, .fa-globe-asia {
  --fa: "";
}

.fa-id-card-clip, .fa-id-card-alt {
  --fa: "";
}

.fa-magnifying-glass-plus, .fa-search-plus {
  --fa: "";
}

.fa-thumbs-up {
  --fa: "";
}

.fa-user-clock {
  --fa: "";
}

.fa-hand-dots, .fa-allergies {
  --fa: "";
}

.fa-file-invoice {
  --fa: "";
}

.fa-window-minimize {
  --fa: "";
}

.fa-mug-saucer, .fa-coffee {
  --fa: "";
}

.fa-brush {
  --fa: "";
}

.fa-file-half-dashed {
  --fa: "";
}

.fa-mask {
  --fa: "";
}

.fa-magnifying-glass-minus, .fa-search-minus {
  --fa: "";
}

.fa-ruler-vertical {
  --fa: "";
}

.fa-user-large, .fa-user-alt {
  --fa: "";
}

.fa-train-tram {
  --fa: "";
}

.fa-user-nurse {
  --fa: "";
}

.fa-syringe {
  --fa: "";
}

.fa-cloud-sun {
  --fa: "";
}

.fa-stopwatch-20 {
  --fa: "";
}

.fa-square-full {
  --fa: "";
}

.fa-magnet {
  --fa: "";
}

.fa-jar {
  --fa: "";
}

.fa-note-sticky, .fa-sticky-note {
  --fa: "";
}

.fa-bug-slash {
  --fa: "";
}

.fa-arrow-up-from-water-pump {
  --fa: "";
}

.fa-bone {
  --fa: "";
}

.fa-table-cells-row-unlock {
  --fa: "";
}

.fa-user-injured {
  --fa: "";
}

.fa-face-sad-tear, .fa-sad-tear {
  --fa: "";
}

.fa-plane {
  --fa: "";
}

.fa-tent-arrows-down {
  --fa: "";
}

.fa-exclamation {
  --fa: "!";
}

.fa-arrows-spin {
  --fa: "";
}

.fa-print {
  --fa: "";
}

.fa-turkish-lira-sign, .fa-try, .fa-turkish-lira {
  --fa: "";
}

.fa-dollar-sign, .fa-dollar, .fa-usd {
  --fa: "$";
}

.fa-x {
  --fa: "X";
}

.fa-magnifying-glass-dollar, .fa-search-dollar {
  --fa: "";
}

.fa-users-gear, .fa-users-cog {
  --fa: "";
}

.fa-person-military-pointing {
  --fa: "";
}

.fa-building-columns, .fa-bank, .fa-institution, .fa-museum, .fa-university {
  --fa: "";
}

.fa-umbrella {
  --fa: "";
}

.fa-trowel {
  --fa: "";
}

.fa-d {
  --fa: "D";
}

.fa-stapler {
  --fa: "";
}

.fa-masks-theater, .fa-theater-masks {
  --fa: "";
}

.fa-kip-sign {
  --fa: "";
}

.fa-hand-point-left {
  --fa: "";
}

.fa-handshake-simple, .fa-handshake-alt {
  --fa: "";
}

.fa-jet-fighter, .fa-fighter-jet {
  --fa: "";
}

.fa-square-share-nodes, .fa-share-alt-square {
  --fa: "";
}

.fa-barcode {
  --fa: "";
}

.fa-plus-minus {
  --fa: "";
}

.fa-video, .fa-video-camera {
  --fa: "";
}

.fa-graduation-cap, .fa-mortar-board {
  --fa: "";
}

.fa-hand-holding-medical {
  --fa: "";
}

.fa-person-circle-check {
  --fa: "";
}

.fa-turn-up, .fa-level-up-alt {
  --fa: "";
}

.sr-only, .fa-sr-only, .sr-only-focusable:not(:focus), .fa-sr-only-focusable:not(:focus) {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

:root, :host {
  --fa-style-family-classic: "Font Awesome 6 Free";
  --fa-font-solid: normal 900 1em / 1 "Font Awesome 6 Free";
}

@font-face {
  font-family: "Font Awesome 6 Free";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("fa-solid-900.570c0093.woff2") format("woff2"), url("fa-solid-900.6b0f68f4.ttf") format("truetype");
}

.fas, .fa-solid {
  font-weight: 900;
}

body {
  color: #859900;
  background: #060606;
  margin: 0;
  font-family: Kode Mono, monospace;
  overflow: hidden;
}

body ::selection {
  background: #666;
}

a {
  color: #859900;
  text-decoration: none;
}

.container {
  justify-content: center;
  align-items: center;
  height: 100dvh;
  display: flex;
}

.container .text {
  text-align: center;
  width: 86vw;
  font-size: 1.66em;
}

.container .text > a {
  opacity: 0;
  margin-left: 8px;
  margin-right: 8px;
  transition: transform .666s, color .666s;
  animation: .666s forwards fadeInUnblur;
  display: inline-block;
}

.container .text > a:hover {
  color: #b7d200;
  transform: scale(1.0333);
}

.container .text > a:active {
  color: #e7ff45;
  transform: scale(1.0666);
}

.container .text > a:first-child {
  animation-delay: .766s;
}

.container .text > a:nth-child(2) {
  animation-delay: .866s;
}

.container .text > a:nth-child(3) {
  animation-delay: .966s;
}

.container .text > a:nth-child(4) {
  animation-delay: 1.066s;
}

.container .text > a:nth-child(5) {
  animation-delay: 1.166s;
}

.container .text > a:nth-child(6) {
  animation-delay: 1.266s;
}

.container .text > a:nth-child(7) {
  animation-delay: 1.366s;
}

.container .text > a:nth-child(8) {
  animation-delay: 1.466s;
}

.container .text > a:nth-child(9) {
  animation-delay: 1.566s;
}

.container .text > a:nth-child(10) {
  animation-delay: 1.666s;
}

.container .text .subcontainer {
  opacity: 0;
  box-sizing: border-box;
  border: 3px solid #859900;
  border-radius: 15px;
  width: 0;
  max-height: 0;
  margin-bottom: 10px;
  transition: max-height .666s;
  animation: .666s .666s forwards lineglow;
  box-shadow: 0 0 5px #859900;
}

.container .text .subcontainer :first-child {
  margin-top: 5px;
}

.container .text .subcontainer :last-child {
  margin-bottom: 5px;
}

.container .text .textcontainer > *, .container .text .subcontainer > * {
  height: auto;
  margin: 0;
}

.container .text .textcontainer > :not(a), .container .text .subcontainer > :not(a) {
  animation: .666s forwards fadeInUnblur;
}

.container .text .textcontainer > * .glow, .container .text .subcontainer > * .glow {
  animation: .666s forwards glow;
}

.container .text .textcontainer > * .fadetext, .container .text .subcontainer > * .fadetext {
  animation: .666s forwards fadeInUnblur, .666s forwards glow;
}

.container .text .hint {
  font-size: .59262em;
}

@media screen and (width >= 720px) {
  .container .text {
    font-size: 3.32em;
  }
}

@media screen and (width >= 1280px) {
  .container .text {
    font-size: 4.98em;
  }
}

@media screen and (width >= 1920px) {
  .container .text {
    font-size: 6.64em;
  }
}

.modal {
  background: #060606;
  border: 3px solid #859900;
  border-radius: 15px;
  width: 0;
  max-width: 500px;
  max-height: 0;
  overflow: hidden;
  box-shadow: 0 0 5px #859900;
}

.modal-close {
  text-align: center;
  padding: 5px;
  font-size: 24px;
}

.modal-backdrop.active .modal {
  animation: .666s forwards modalglow;
}

.modal-backdrop {
  backdrop-filter: blur(5px);
  z-index: 1000;
  visibility: hidden;
  background: #8599001a;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: backdrop-filter .666s;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-backdrop.active {
  visibility: visible;
}

.modal-header {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  display: flex;
}

.player-list {
  padding: 0;
  list-style: none;
}

.player-list li {
  margin-bottom: 10px;
}

.player-list li i {
  text-align: center;
  min-width: 16px;
  margin-right: 10px;
}
/*# sourceMappingURL=index.65a09327.css.map */
