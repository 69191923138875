@use "sass:color";

@use "variables.scss";
@use "animations.scss";
@use "ripple.scss";
@use "fonts.scss";

body {
  font-family: variables.$font-family;
  background: variables.$background-color;
  color: variables.$text-color;
  overflow: hidden;
  margin: 0;

  ::selection {
    background: variables.$selection-color;
  }
}

a {
  color: variables.$text-color;
  text-decoration: none;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100dvh;

  .text {
    font-size: variables.$font-size;
    text-align: center;
    width: 86vw;

    &>a {
      opacity: 0;
      transition: transform variables.$anim-time, color variables.$anim-time;
      display: inline-block;
      animation: fadeInUnblur variables.$anim-time forwards;
      margin-left: 8px;
      margin-right: 8px;

      &:hover {
        transform: scale(1.0333);
        color: color.scale(variables.$text-color, $lightness: variables.$lighten-by);
      }

      &:active {
        transform: scale(1.0666);
        color: color.scale(variables.$text-color, $lightness: variables.$lighten-by*3);
      }
      
      @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
          animation-delay: $i * 0.1s + variables.$anim-time;
        }
      }
    }
    
    .subcontainer {
      *:first-child {
        margin-top: variables.$blur-amount;
      }

      *:last-child {
        margin-bottom: variables.$blur-amount;
      }
      
      opacity: 0;
      width: 0;
      max-height: 0;
      border-radius: variables.$border-radius;
      border: 3px solid variables.$text-color;
      animation: lineglow variables.$anim-time forwards;
      animation-delay: variables.$anim-time;
      box-sizing: border-box;
      transition: max-height variables.$anim-time;
      box-shadow: 0 0 variables.$shadow-amount variables.$text-color;
      margin-bottom: variables.$margin-bottom;
    }

    .textcontainer > *, .subcontainer > * {
      margin: 0;
      height: auto;

      &:not(a) {
        animation: fadeInUnblur variables.$anim-time forwards;
      }

      .glow {
        animation: glow variables.$anim-time forwards;
      }

      .fadetext {
        animation: fadeInUnblur variables.$anim-time forwards, glow variables.$anim-time forwards;
      }
    }

    .hint {
      font-size: calc(variables.$font-size * 0.357);
    }

    @media screen and (min-width: 720px) {
      font-size: calc(variables.$font-size * 2);
    }

    @media screen and (min-width: 1280px) {
      font-size: calc(variables.$font-size * 3);
    }

    @media screen and (min-width: 1920) {
      font-size: calc(variables.$font-size * 4);
    }
  }
}

.modal {
  background: variables.$background-color;
  border-radius: variables.$border-radius;
  border: 3px solid variables.$text-color;
  max-width: 500px;
  width: 0;
  max-height: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  box-shadow: 0 0 variables.$shadow-amount variables.$text-color;
  
  &-close {
    font-size: 24px;
    text-align: center;
    padding: 5px;
  }

  &-backdrop.active & {
    animation: modalglow variables.$anim-time forwards;
  }
  
  &-backdrop {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: color.adjust(variables.$text-color, $alpha: -0.9);
    backdrop-filter: blur(variables.$blur-amount);
    transition: backdrop-filter variables.$anim-time;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    visibility: hidden;
    
    &.active {
      visibility: visible;
    }
  }
  
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: variables.$margin-bottom;
  }
}

.player-list {
  list-style: none;
  padding: 0;
  
  li {
    margin-bottom: variables.$margin-bottom;

    i {
      margin-right: variables.$margin-bottom;
      min-width: 16px;
      text-align: center;
    }
  }
}