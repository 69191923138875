@use "variables";

@use "sass:color";

@keyframes fadeInUnblur {
  0% {
    opacity: 0;
    text-shadow: 0 0 0 variables.$shadow-color;
    filter: blur(variables.$blur-amount);
  }

  100% {
    opacity: 1;
    text-shadow: 0 0 variables.$shadow-amount variables.$shadow-color;
    filter: blur(0px);
  }
}

@keyframes glow {
  0% {
    color: color.scale(variables.$text-color, $lightness: variables.$lighten-by);
    text-shadow: 0 0 calc(variables.$shadow-amount * 2) variables.$shadow-color;
  }

  100% {
    color: variables.$text-color;
    text-shadow: 0 0 variables.$shadow-amount variables.$shadow-color;
  }
}

@keyframes lineglow {
  0% {
    opacity: 0;
    width: 0;
    max-height: 0;
  }

  33% {
    opacity: 1;
    width: 100%;
    max-height: 0;
  }

  100% {
    opacity: 1;
    width: 100%;
    max-height: 100vh;
  }
}

@keyframes modalglow {
  0% {
    opacity: 0;
    width: 0;
    max-height: 0;
    padding: 0;
  }

  33% {
    opacity: 1;
    width: 80%;
    max-height: 0;
    padding: 0;
  }

  100% {
    opacity: 1;
    width: 80%;
    max-height: 80%;
    padding: variables.$border-radius variables.$border-radius*2;
  }
}

@keyframes ripple {
  0% {
    transform: scale(0);
    opacity: 0.8;
  }
  
  100% {
    transform: scale(1);
    opacity: 0;
  }
}