@use "variables";

.ripple {
  position: fixed;

  background-color: #859900;

  $square: 100vw*2;
  width: $square;
  height: $square;

  border-radius: 50%;
  
  pointer-events: none;

  opacity: 0.8;
  filter: blur(variables.$blur-amount);
  animation: ripple variables.$anim-time forwards;
}